import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Stack,
  Divider,
  Chip,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  generateAIBlog,
  generateKeywords,
  generateSubheadings,
} from '../Services/Services'

const AiBlogGenerator = ({
  open,
  onClose,
  onGenerateComplete,
  defaultTite = '',
  defaultSubheadings = ['', '', ''],
  defaultKeywords = ['', '', ''],
}) => {
  const [title, setTitle] = useState(defaultTite)
  const [subheadings, setSubheadings] = useState(
    defaultSubheadings?.length > 0 ? defaultSubheadings : ['', '', '']
  )
  const [keywords, setKeywords] = useState(
    defaultKeywords?.length > 0 ? defaultKeywords : ['', '', '']
  )
  const [loading, setLoading] = useState(false)
  const [keywordsLoading, setKeywordsLoading] = useState(false)
  const [subheadingsLoading, setSubheadingsLoading] = useState(false)
  const [error, setError] = useState('')

  // Preview dialog state
  const [previewOpen, setPreviewOpen] = useState(false)
  const [generatedHtml, setGeneratedHtml] = useState('')

  const handleAddSubheading = () => {
    setSubheadings([...subheadings, ''])
  }

  const handleSubheadingChange = (index, value) => {
    const newSubheadings = [...subheadings]
    newSubheadings[index] = value
    setSubheadings(newSubheadings)
  }

  const handleRemoveSubheading = (index) => {
    const newSubheadings = subheadings.filter((_, i) => i !== index)
    setSubheadings(newSubheadings)
  }

  const handleAddKeyword = () => {
    setKeywords([...keywords, ''])
  }

  const handleKeywordChange = (index, value) => {
    const newKeywords = [...keywords]
    newKeywords[index] = value
    setKeywords(newKeywords)
  }

  const handleRemoveKeyword = (index) => {
    const newKeywords = keywords.filter((_, i) => i !== index)
    setKeywords(newKeywords)
  }

  const handleAutoGenerateKeywords = async () => {
    if (!title.trim()) {
      setError('Please enter a title first to generate keywords')
      return
    }

    try {
      setKeywordsLoading(true)
      setError('')

      // Call API to generate keywords based on title
      const response = await generateKeywords({ title, count: 10 })

      // Update keywords with the generated ones
      if (response && response.keywords && response.keywords.length > 0) {
        setKeywords(response.keywords)
      } else {
        setError('Failed to generate keywords')
      }
    } catch (err) {
      setError(err.message || 'Failed to generate keywords')
    } finally {
      setKeywordsLoading(false)
    }
  }

  const handleAutoGenerateSubheadings = async () => {
    if (!title.trim()) {
      setError('Please enter a title first to generate subheadings')
      return
    }

    try {
      setSubheadingsLoading(true)
      setError('')

      // Call API to generate subheadings based on title
      const response = await generateSubheadings({ title, count: 10 })

      // Update subheadings with the generated ones
      if (response && response.subheadings && response.subheadings.length > 0) {
        setSubheadings(response.subheadings)
      } else {
        setError('Failed to generate subheadings')
      }
    } catch (err) {
      setError(err.message || 'Failed to generate subheadings')
    } finally {
      setSubheadingsLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      setError('')

      // Filter out empty subheadings and keywords
      const filteredSubheadings = subheadings.filter(
        (item) => item.trim() !== ''
      )
      const filteredKeywords = keywords.filter((item) => item.trim() !== '')

      // Here you would make your API call
      const response = await generateAIBlog({
        title,
        subheadings: filteredSubheadings,
        keywords: filteredKeywords,
        targetWordCount: filteredSubheadings.length * 180,
      })

      // Assuming the API returns the generated HTML content
      const htmlContent = response.htmlContent || '<p>Placeholder content</p>'

      // Store the generated HTML
      setGeneratedHtml(htmlContent)

      // Open the preview dialog
      setPreviewOpen(true)
    } catch (err) {
      setError(err.message || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  const handleAccept = () => {
    // Close preview dialog
    setPreviewOpen(false)

    // Call the callback with the generated HTML
    onGenerateComplete({ htmlContent: generatedHtml, keywords })

    // Close the main dialog
    onClose()
  }

  const handleDiscard = () => {
    // Close only the preview dialog
    setPreviewOpen(false)
  }

  const handleRegenerate = async () => {
    // Close the preview dialog
    setPreviewOpen(false)

    // Re-run the generate function
    await handleSubmit()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={loading ? null : onClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="ai-blog-generator-dialog"
      >
        <DialogTitle id="ai-blog-generator-dialog">
          AI Blog Generator
        </DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Fill in the details below to generate your blog content.
          </DialogContentText>

          <Box
            component={Paper}
            elevation={0}
            variant="outlined"
            sx={{ p: 3, mb: 3 }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="blog-title"
              label="Blog Title"
              type="text"
              fullWidth
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ mb: 3 }}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography variant="h6">Subheadings</Typography>
              <Tooltip title="Auto-generate subheadings based on title">
                <Button
                  startIcon={
                    subheadingsLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AutoFixHighIcon />
                    )
                  }
                  onClick={handleAutoGenerateSubheadings}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  disabled={!title.trim() || subheadingsLoading}
                >
                  {subheadingsLoading ? 'Generating...' : 'Auto-Generate'}
                </Button>
              </Tooltip>
            </Box>

            <Stack spacing={2} sx={{ mb: 2 }}>
              {subheadings.map((subheading, index) => (
                <Box
                  key={`subheading-${index}`}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    label={`Subheading ${index + 1}`}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={subheading}
                    onChange={(e) =>
                      handleSubheadingChange(index, e.target.value)
                    }
                    sx={{ my: 0.5 }}
                  />
                  <IconButton
                    color="default"
                    onClick={() => handleRemoveSubheading(index)}
                    disabled={subheadings.length <= 1}
                    sx={{
                      ml: 1,
                      opacity: 0.3,
                      '&:hover': {
                        opacity: 0.8,
                        color: 'error.main',
                      },
                    }}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Stack>

            <Button
              startIcon={<AddIcon />}
              onClick={handleAddSubheading}
              variant="outlined"
              size="small"
              sx={{ mb: 3 }}
            >
              Add Subheading
            </Button>

            <Divider sx={{ my: 3 }} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography variant="h6">Keywords</Typography>
              <Tooltip title="Auto-generate keywords based on title">
                <Button
                  startIcon={
                    keywordsLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AutoFixHighIcon />
                    )
                  }
                  onClick={handleAutoGenerateKeywords}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  disabled={!title.trim() || keywordsLoading}
                >
                  {keywordsLoading ? 'Generating...' : 'Auto-Generate'}
                </Button>
              </Tooltip>
            </Box>

            <Stack spacing={2} sx={{ mb: 2 }}>
              {keywords.map((keyword, index) => (
                <Box
                  key={`keyword-${index}`}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    label={`Keyword ${index + 1}`}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={keyword}
                    onChange={(e) => handleKeywordChange(index, e.target.value)}
                    sx={{ my: 0.5 }}
                  />
                  <IconButton
                    color="default"
                    onClick={() => handleRemoveKeyword(index)}
                    disabled={keywords.length <= 1}
                    sx={{
                      ml: 1,
                      opacity: 0.3,
                      '&:hover': {
                        opacity: 0.8,
                        color: 'error.main',
                      },
                    }}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Stack>

            <Button
              startIcon={<AddIcon />}
              onClick={handleAddKeyword}
              variant="outlined"
              size="small"
            >
              Add Keyword
            </Button>
          </Box>

          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disableElevation
            disabled={loading || !title.trim()}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Generating...' : 'Generate Blog'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={handleDiscard}
        fullWidth
        maxWidth="lg"
        aria-labelledby="blog-preview-dialog"
      >
        <DialogTitle id="blog-preview-dialog">
          Preview Generated Content
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Keywords:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {keywords
                  .filter((k) => k.trim() !== '')
                  .map((keyword, index) => (
                    <Chip
                      key={index}
                      label={keyword}
                      size="small"
                      variant="outlined"
                    />
                  ))}
              </Box>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Blog content preview */}
          <Box
            sx={{
              mt: 2,
              p: 2,
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: 1,
              bgcolor: 'background.paper',
              minHeight: '400px',
              maxHeight: '500px',
              overflow: 'auto',
            }}
          >
            <div
              style={{
                fontFamily: 'Arial, sans-serif',
              }}
              dangerouslySetInnerHTML={{ __html: generatedHtml }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
          <Button
            onClick={handleDiscard}
            startIcon={<CancelIcon />}
            color="error"
            variant="outlined"
          >
            Discard
          </Button>

          <Box>
            <Button
              onClick={handleRegenerate}
              startIcon={<RefreshIcon />}
              color="warning"
              variant="contained"
              sx={{ mr: 2 }}
            >
              Regenerate
            </Button>

            <Button
              onClick={handleAccept}
              startIcon={<CheckCircleIcon />}
              color="success"
              variant="contained"
            >
              Accept
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AiBlogGenerator

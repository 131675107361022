import {
  Card,
  CardContent,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  Grid,
  Select,
  MenuItem,
  Alert,
  Tooltip,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import FormItem from '../Common/FormItem'
import RichEditor from '../Common/RichEditor'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import React, { useRef, useState, useEffect } from 'react'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import slugify from 'slugify'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  addBlog,
  generateMetadesription,
  getBlogDetails,
  getObjectDiff,
  updateBlog,
  uploadImage,
} from '../Services/Services'
import Loader from '../Common/Loader'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { secondaryColor } from '../constants'
import Container from '../Common/Container'
import useNotify from '../Hooks/useNotify'
import HtmlViewer from '../Common/HtmlViewer'
import HtmlEditor from '../Common/HtmlEditor'
import AiBlogGenerator from '../Common/AiBlogGenerator'
import { AutoFixHigh } from '@mui/icons-material'

const Title = {
  0: 'Edit Title & Banner',
  1: 'Edit Blog Content',
  2: 'Edit Meta info & Other Details',
}
const slugifyConf = {
  replacement: '-',
  lower: true,
  strict: true,
  trim: true,
  remove: /[*+~.()'"!:@?]/g,
}

function extractSubheadings(htmlString) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const headings = Array.from(doc.querySelectorAll('h2')).map((h2) =>
    h2.textContent.trim()
  )
  return headings
}

export default function EditBlog() {
  const params = useParams()
  const { notifyError } = useNotify()
  const [random] = useState(Math.random())
  const { isLoading, data, error, refetch } = useQuery(
    ['blogDetails', params.id, random],
    () => getBlogDetails(params.id)
  )
  const editType = parseInt(params.type)
  const navigate = useNavigate()
  const fileUploadRef = useRef(null)
  const initialize = useRef(false)
  const [activeState, setActiveState] = useState(0)

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState(null)
  const [date, setDate] = useState(null)
  const [tags, setTags] = useState('')
  const [slug, setSlug] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [uploadProgress, setUploadProgress] = useState(false)
  const [imageResolutionWarning, setImageResolutionWarning] = useState(false)
  const [lang, setLang] = useState('en')
  const [reqInProgress, setReqInProgress] = useState(false)
  const [snackbarConf, setSnackbarConf] = useState({})
  const [htmlEditorOpen, setHtmlEditorOpen] = useState(false)
  const [metaDescriptionloading, setMetaDescriptionLoading] = useState(false)

  const [openAIGenerator, setOpenAIGenerator] = useState(false)
  const [keywords, setKeywords] = useState([])
  const handleOpenAIGenerator = () => {
    setOpenAIGenerator(true)
  }

  const handleCloseAIGenerator = () => {
    setOpenAIGenerator(false)
  }

  const handleGenerateComplete = ({ htmlContent, keywords }) => {
    setContent(htmlContent)
    setKeywords(keywords)
    setOpenAIGenerator(false)
  }

  const handleMetaDescription = async () => {
    setMetaDescriptionLoading(true)
    try {
      const response = await generateMetadesription({
        title,
        keywords,
      })
      console.log('Meta Description:', response)
      setMetaDescription(response.metaDescription)
    } catch (e) {
      if (e?.response?.data?.message) {
        setSnackbarConf({
          open: true,
          message: e?.response?.data?.message,
        })
      }
    } finally {
      setMetaDescriptionLoading(false)
    }
  }

  useEffect(() => {
    if (!initialize.current && data && Object.keys(data).length) {
      setTitle(data.title)
      setContent(data.content)
      setImage(data.banner || false)
      setImageUrl(data.banner)
      setImageThumbnailUrl(data.thumbnail)
      setDate(dayjs(data.blogDate))
      setTags(data?.tags?.length > 0 ? data.tags.join(', ') : '')
      setSlug(data.slug)
      setMetaTitle(data.metaTitle)
      setMetaDescription(data.metaDescription)
      setLang(data.lang)
      setKeywords(data.keywords)
      initialize.current = true
    }
  }, [data])

  const isSafeEmpty = (str) => {
    if (!str) return true
    if (str.trim().length === 0) return true
    return false
  }

  const isIncorrect = () => {
    let diff
    switch (editType) {
      case 0:
        // title and banner
        diff = getObjectDiff(
          {
            title: data.title,
            banner: data.banner,
            thumbnail: data.thumbnail,
          },
          {
            title,
            banner: imageUrl,
            thumbnail: imageThumbnailUrl,
          }
        )

        if (diff) {
          return false
        }
        return true
      case 1:
        return content === data.content
      case 2:
        diff = getObjectDiff(
          {
            blogDate: data.blogDate,
            tags: data.tags,
            lang: lang,
            slug: data.slug,
            metaTitle: data.metaTitle,
            metaDescription: data.metaDescription,
          },
          {
            blogDate: date,
            tags: tags.split(',').map((t) => t.trim()),
            lang: lang,
            slug,
            metaTitle,
            metaDescription,
          }
        )

        if (diff) {
          return false
        }
        return true
      default:
        return true
    }
  }

  const triggerfileUpload = () => {
    fileUploadRef.current.click()
  }
  const fileUpladed = async (event) => {
    setImage(event.target.files[0])
    setUploadProgress(true)
    setImageResolutionWarning(false)
    try {
      const response = await uploadImage(event.target.files[0])
      setImageUrl(response.url)
      setImageThumbnailUrl(response.thumbnailUrl)
    } catch (e) {
    } finally {
      setUploadProgress(false)
    }
  }

  const autoGenerateSlug = () => {
    setSlug(slugify(title, slugifyConf))
  }

  const autoGenerateMetaTitle = () => {
    setMetaTitle(title)
  }

  const onImgLoad = (e) => {
    const height = e.target.offsetHeight
    const width = e.target.offsetWidth

    const resolution = width / height

    if (resolution >= 1.45 && resolution <= 1.55) {
      setImageResolutionWarning(false)
    } else {
      setImageResolutionWarning(true)
    }
  }

  const save = async () => {
    setReqInProgress(true)
    let diff
    switch (editType) {
      case 0:
        // title and banner
        diff = getObjectDiff(
          {
            title: data.title,
            banner: data.banner,
            thumbnail: data.thumbnail,
          },
          {
            title,
            banner: imageUrl,
            thumbnail: imageThumbnailUrl,
          }
        )
        break
      case 1:
        diff = getObjectDiff(
          {
            content: data.content,
            keywords: data.keywords,
          },
          {
            content,
            keywords,
          }
        )
        break
      case 2:
        // slug, meta title, meta description, tags, blog language
        diff = getObjectDiff(
          {
            blogDate: data.blogDate,
            // tags: data?.tags?.join(', '),
            tags: data.tags?.length > 0 ? data.tags.join(', ') : '',
            lang: lang,
            slug: data.slug,
            metaTitle: data.metaTitle,
            metaDescription: data.metaDescription,
          },
          {
            blogDate: date,
            tags: tags.split(',').map((t) => t.trim()),
            lang: lang,
            slug,
            metaTitle,
            metaDescription,
          }
        )
        break
      default:
    }
    try {
      if (diff) {
        await updateBlog(params.id, diff)
        navigate(`/blogs/${params.id}`)
      }
    } catch (e) {
      const message = e?.response?.data?.message || 'Something went wrong'
      notifyError(message)
    }
    setReqInProgress(false)
  }
  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <Typography variant="h5" color="initial">
          {Title[params.type]}
        </Typography>

        <Button
          variant="contained"
          onClick={save}
          disabled={isIncorrect()}
          sx={{
            background: secondaryColor,
            color: 'black',
            '&:hover': {
              background: '#a3dcfc',
            },
          }}
        >
          Save
        </Button>
      </Box>

      <Card>
        <CardContent>
          {editType === 0 && (
            <React.Fragment>
              <FormItem label={'Title'} required={true}>
                <TextField
                  multiline
                  fullWidth
                  sx={{ borderRadius: '20px' }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  InputProps={{
                    sx: {
                      borderRadius: '24px',
                    },
                  }}
                  inputProps={{
                    style: {
                      fontWeight: 'bold',
                      fontSize: '22px',
                    },
                  }}
                ></TextField>
              </FormItem>

              <FormItem>
                <Card variant="outlined">
                  <CardContent>
                    {!image && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100px',
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={triggerfileUpload}
                        >
                          Upload Banner Image
                        </Button>
                      </Box>
                    )}
                    {image && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '300px',
                        }}
                      >
                        <Box>
                          {imageUrl && !uploadProgress && (
                            <>
                              <Box
                                sx={{
                                  height: '200px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <img
                                  onLoad={onImgLoad}
                                  src={imageUrl}
                                  alt="sample"
                                  style={{
                                    height: '100%',
                                    borderRadius: '10px',
                                    boxShadow:
                                      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                  }}
                                />
                              </Box>

                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  my: 1,
                                }}
                              >
                                <IconButton
                                  aria-label=""
                                  onClick={triggerfileUpload}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Box>
                              {imageResolutionWarning && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    my: 1,
                                  }}
                                >
                                  <Alert severity="warning" color="warning">
                                    Image resolution is not correct. Keep the
                                    image resolution 3:2
                                  </Alert>
                                </Box>
                              )}
                              {!imageResolutionWarning && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    my: 1,
                                  }}
                                >
                                  <Alert severity="success" color="success">
                                    Resolution looks good!
                                  </Alert>
                                </Box>
                              )}
                            </>
                          )}
                          {uploadProgress && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </FormItem>
            </React.Fragment>
          )}

          {editType === 1 && (
            <React.Fragment>
              {initialize.current && (
                <>
                  {isEmpty(content) && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setHtmlEditorOpen(true)}
                      >
                        Add Html
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ mx: 2 }}
                        onClick={() => handleOpenAIGenerator(true)}
                      >
                        Generate using AI
                      </Button>
                    </Box>
                  )}
                  {!isEmpty(content) && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setHtmlEditorOpen(true)}
                        >
                          Edit Html
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{ mx: 2 }}
                          onClick={() => handleOpenAIGenerator(true)}
                        >
                          Generate using AI
                        </Button>
                      </Box>
                      <HtmlViewer html={content} />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setHtmlEditorOpen(true)}
                        >
                          Edit Html
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{ mx: 2 }}
                          onClick={() => handleOpenAIGenerator(true)}
                        >
                          Generate using AI
                        </Button>
                      </Box>
                    </>
                  )}
                  <HtmlEditor
                    open={htmlEditorOpen}
                    html={content}
                    onChange={(e) => {
                      setContent(e)
                      setHtmlEditorOpen(false)
                    }}
                    onClose={() => setHtmlEditorOpen(false)}
                  />
                  {openAIGenerator && (
                    <AiBlogGenerator
                      open={openAIGenerator}
                      onClose={handleCloseAIGenerator}
                      onGenerateComplete={handleGenerateComplete}
                      defaultTite={title}
                      defaultSubheadings={extractSubheadings(content)}
                      defaultKeywords={keywords}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          )}

          {editType === 2 && (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <FormItem label={'Blog Date'} required={true}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth>
                        <DatePicker value={date} onChange={(e) => setDate(e)} />
                      </FormControl>
                    </LocalizationProvider>
                  </FormItem>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormItem label={'Tags'} required={true}>
                    <TextField
                      fullWidth
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                      helperText="Comma separated values e.g. Oral Health, Periodontics"
                    ></TextField>
                  </FormItem>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormItem label={'Blog Language'} required={true}>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={lang}
                      onChange={(e) => setLang(e.target.value)}
                    >
                      <MenuItem value={'en'}>English</MenuItem>
                      <MenuItem value={'hi'}>Hindi</MenuItem>
                    </Select>
                  </FormItem>
                </Grid>
              </Grid>

              <FormItem label={'Slug'} required={true}>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={'text'}
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={autoGenerateSlug}
                          onMouseDown={autoGenerateSlug}
                          edge="end"
                        >
                          <AutorenewIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </FormItem>
              <FormItem label={'Meta Title'} required={true}>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={'text'}
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={autoGenerateMetaTitle}
                          onMouseDown={autoGenerateMetaTitle}
                          edge="end"
                        >
                          <AutorenewIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </FormItem>

              <FormItem label={'Meta Description'} required={true}>
                <TextField
                  fullWidth
                  multiline
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  helperText={
                    metaDescription?.length
                      ? `Character Count : ${metaDescription.length}`
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Auto-generate meta descripion based on title">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleMetaDescription}
                            edge="end"
                            color="secondary"
                            disabled={metaDescriptionloading}
                          >
                            {metaDescriptionloading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <AutoFixHigh />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </FormItem>
            </React.Fragment>
          )}

          <input
            ref={fileUploadRef}
            style={{ display: 'none' }}
            type="file"
            onChange={(e) => fileUpladed(e)}
          />
        </CardContent>
      </Card>
    </Container>
  )
}

function isEmpty(value) {
  return (
    value === undefined || value === null || value === '' || value.trim() === ''
  )
}
